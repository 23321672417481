<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      :baseFormConfig="baseFormConfig"
      :pageTitle="pageTitle"
      :pageInfo="pageInfo"
      pageType="view"
    >
      <template #customForm>
        <el-col :span="12">
          <el-form-item :label="$t('user.resource-menu')" class="form-item">
            <resource-tree
              class="resource-menu"
              ref="resourceTreeRef"
              :style="{ height: '300px', width: '100%' }"
              :resources="allTreeResource"
              @handle-node-click="handleNodeClick"
            ></resource-tree>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item class="form-item">
            <template #label>
              <span>{{ $t('user.resource-btn') }}</span>
              <span
                v-if="currentMenuTitle"
                :style="{
                  color: $store.getters.cssVar.menuBg,
                  'font-weight': 'bold',
                  'margin-left': '10px'
                }"
                >{{ currentMenuTitle }}</span
              >
            </template>
            <btn-table :listData="btnListData"></btn-table>
          </el-form-item>
        </el-col>
      </template>
    </page-form>
  </div>
</template>

<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import { baseFormConfig } from './config/resource.view.js'
import { useStore } from 'vuex'
import { ref, onBeforeMount } from 'vue'
import i18n from '@/i18n'
import { useRoute } from 'vue-router'
import resourceTree from '@/components/resourceMenu/resource-tree.vue'
import btnTable from '@/components/resourceMenu/btn-table.vue'
import _ from 'lodash' // 导入loadsh插件
import { RESOURCEMENUBTNLIST } from '@/utils/constant/index'
import { getItem, setItem } from '@/utils/storage'

// 页面标题
const pageTitle = ref('')
const { t } = i18n.global

pageTitle.value = t('general.router-resource-group-view')
const store = useStore()
const route = useRoute()
const id = route.params.id
const allTreeResource = ref([])
const selectedResources = ref([])
const resourceTreeRef = ref(null)
const pageInfo = ref({})
const currentMenuTitle = ref('')
const resourceTreeParentNodeID = ref(0)
const btnListData = ref([])
const btnList = ref([])
const selectedBtns = ref([])

const resuorceMenuBtnList = store.getters.resuorceMenuBtnList || []

const initMenuList = async () => {
  const resuorceMenu = _.cloneDeep(resuorceMenuBtnList)
  allTreeResource.value = [
    {
      id: '0',
      name: 'Resources',
      children: resuorceMenu,
      level: 0
    }
  ]
  const result = handleEntireTree(allTreeResource.value)
  allTreeResource.value = result
}

const initData = async () => {
  const data = await store.dispatch('user/getResourceGroupById', id)
  pageInfo.value = data

  const permissionMenuList = await store.dispatch(
    'user/getPermissionMenuList',
    id
  )
  if (permissionMenuList && permissionMenuList.length)
    for (const index in permissionMenuList) {
      selectedResources.value.push(permissionMenuList[index].id)
    }
  resourceTreeRef.value.handleInitialEditData(selectedResources.value)
}

const initPermissionBtnList = async () => {
  const data = await store.dispatch('user/getPermissionBtnList', id)
  if (data && data.length > 0) {
    selectedBtns.value = data.map((item) => item.id)
  }
}
// 给resuorceMenuBtnList初始化为按钮带勾选参数的数组
const initBtnCheckStatus = () => {
  const resuorceMenu = _.cloneDeep(resuorceMenuBtnList)

  setItem(RESOURCEMENUBTNLIST, handleEntireBtn(resuorceMenu))
}

// 判断按钮id是否在已勾选按钮数组中
const isCheckedBtn = (id) => {
  return selectedBtns.value.includes(id)
}

// 给资源按钮树添加button isChecked属性
const handleEntireBtn = (resources) => {
  return resources.map((item) => {
    if (item.children && item.children.length > 0) {
      // 递归处理
      item.children = handleEntireBtn(item.children)
    }
    if (item.btnList && item.btnList.length > 0) {
      item.btnList = handleEntireBtn(item.btnList)
    }

    if (item.level > 2) {
      return {
        ...item,
        isChecked: isCheckedBtn(item.id)
      }
    }
    return { ...item }
  })
}
const handleEntireTree = (resources) => {
  return resources.map((item) => {
    if (item.children && item.children.length > 0) {
      item.children = handleEntireTree(item.children)
    } else {
      item.children = []
    }
    return {
      id: item.id,
      label: t(item.name),
      disabled: true,
      children: item.children,
      level: item.level
    }
  })
}
const handleNodeClick = (data) => {
  const { id } = data
  // 判断是否父级id
  const isParentNodes = resourceTreeParentNodeID.value === id

  // 当点击第一层父级时，则不执行函数
  if (!isParentNodes) clickMenu(data)
}

const clickMenu = (data) => {
  initCurrentMenuBtnList(data.id)
  currentMenuTitle.value = t('user.current-resource-menu') + ': ' + data.label
}

const initCurrentMenuBtnList = async (id) => {
  // 获取当前点击菜单的button 数组
  const btnArr = getBtnList(id)
  const btnListFormat = []
  // 当点击的菜单没有被勾选时,那按钮列表应为禁用状态
  for (const i in btnArr) {
    btnListFormat.push({
      id: btnArr[i].id,
      isChecked: btnArr[i].isChecked,
      disable: true,
      btn: btnArr[i]
    })
  }
  btnListData.value = btnListFormat
}

// 根据当前id值获取按钮列表
const getBtnList = (id) => {
  const resuorceMenu = getItem(RESOURCEMENUBTNLIST) || []
  resuorceMenu.forEach((item) => {
    if (item.children && item.children.length) {
      item.children.forEach((child) => {
        if (child.id === id) {
          if (child.btnList && child.btnList.length > 0) {
            btnList.value = child.btnList.map((item) => {
              item.isChecked = selectedBtns.value.includes(item.id)

              return item
            })
          } else {
            btnList.value = []
          }
        }
      })
    } else {
      btnList.value = []
    }
  })
  return btnList.value
}

initMenuList()
initData()
onBeforeMount(async () => {
  // 需要保证加载initPermissionBtnList后再加载initBtnCheckStatus
  await initPermissionBtnList()
  initBtnCheckStatus()
})
</script>

<style lang="scss" scoped>
.base-form {
  .form-item {
    padding: 5px 20px;
    .resource-menu {
      border: 1px solid #dcdfe6;
      height: 300px;
      overflow-y: scroll;
    }
  }
}
</style>
